import React, { useEffect, useContext } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";

// Context
import { ConversationsMenuColor } from "../context/conversation-menu-color";

// Containers
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Utils
import kebabCase from "lodash.kebabcase";
import { ConditionalWrapper } from "../utils/conditional-wrapper";

const Row = styled.div`
  width: 100%;

  &.text-row {
    margin: 0 0 70px 0;

    @media (max-width: 767px) {
      margin: 0 0 25px 0;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  width: 100%;

  @media (max-width: 767px) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
`;

const Article = styled.div`
  padding: 0 20px;

  &:nth-of-type(even) {
    background-color: #fff;
  }

  & .image-caption {
    max-width: 570px;
  }

  @media (max-width: 767px) {
    padding: 0 15px;
  }
`;

const TitleBar = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  width: 100%;
  margin: 0 0 130px 0;

  @media (max-width: 767px) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;

    margin: 0 0 25px 0;
  }

  & .details {
    grid-column: 5 / 13;

    @media (max-width: 767px) {
      grid-column: span 6;
    }

    & p,
    & h1 {
      margin: 0;
    }

    & .date {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;

      text-transform: lowercase;

      @media (max-width: 600px) {
        font-size: 9px;
        line-height: 11px;
      }
    }

    & .title h1 {
      font-size: 26px;
      line-height: 34px;
      letter-spacing: 0.03em;

      text-transform: lowercase;

      @media (max-width: 960px) {
        font-size: 20px;
      }

      @media (max-width: 600px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`;

const TitleSpacer = styled.div`
  height: 140px;
  width: 100%;

  &:first-of-type {
    height: 200px;
  }

  @media (max-width: 767px) {
    height: 45px;

    &:first-of-type {
      height: 105px;
    }
  }
`;

export const SingleConversation = ({ conversation, index }) => {
  const [menuColor, setMenuColor] = useContext(ConversationsMenuColor);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (inView && index % 2 === 0) {
      // Index is even
      setMenuColor(`#fafaf5`);
    } else if (inView && index % 2 !== 0) {
      // Index is not even
      setMenuColor(`#fff`);
    }
  }, [inView]);

  const conversationContent = conversation.conversation.document.data.body.map(
    (content, index) => {
      if (content.slice_type === "interview_text") {
        const text = content.items.map((item, index) => (
          <div
            className="interview-container"
            key={`single_interview_text_item_${index}`}
          >
            <p className="name small-text">{item.name}</p>
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: item.text.html,
              }}
            />
          </div>
        ));

        return (
          <Row key={content.id} className="text-row">
            <Grid>
              {content.primary.image.fluid !== null && (
                <div className="image-container">
                  <ConditionalWrapper
                    condition={
                      content.primary.link !== null &&
                      content.primary.link.url !== ""
                    }
                    wrapper={(children) => (
                      <Link to={content.primary.link.url}>{children}</Link>
                    )}
                  >
                    <AspectRatioImageContainer image={content.primary.image}>
                      <img
                        srcSet={content.primary.image.fluid.srcSet}
                        src={content.primary.image.fluid.src}
                        alt={content.primary.image.alt}
                        loading="lazy"
                      />
                    </AspectRatioImageContainer>
                  </ConditionalWrapper>
                  <p className="image-caption small-text">
                    {content.primary.image.alt}
                  </p>
                </div>
              )}

              <div className="text-container">{text}</div>
            </Grid>
          </Row>
        );
      }

      if (content.slice_type === "text") {
        return (
          <Row key={content.id} className="text-row">
            <Grid>
              <div
                className={`positioned-text ${content.primary.text_position.toLowerCase()}`}
                dangerouslySetInnerHTML={{
                  __html: content.primary.text.html,
                }}
              />
            </Grid>
          </Row>
        );
      }

      if (content.slice_type === "single_image") {
        return (
          <Row key={content.id}>
            <Grid>
              <div
                className={`positioned-image ${content.primary.image_position.toLowerCase()} ${content.primary.image_size.toLowerCase()}`}
              >
                {content.primary.image.fluid !== null && (
                  <ConditionalWrapper
                    condition={
                      content.primary.link !== null &&
                      content.primary.link.url !== ""
                    }
                    wrapper={(children) => (
                      <Link to={content.primary.link.url}>{children}</Link>
                    )}
                  >
                    <AspectRatioImageContainer image={content.primary.image}>
                      <img
                        srcSet={content.primary.image.fluid.srcSet}
                        src={content.primary.image.fluid.src}
                        alt={content.primary.image.alt}
                        loading="lazy"
                      />
                    </AspectRatioImageContainer>
                  </ConditionalWrapper>
                )}
                <div
                  className="image-caption small-text"
                  dangerouslySetInnerHTML={{
                    __html: content.primary.image_caption.html,
                  }}
                />
              </div>
            </Grid>
          </Row>
        );
      }

      if (content.slice_type === "two_images") {
        return (
          <Row key={content.id}>
            <Grid>
              <div
                className={`multi-positioned-images layout-${content.primary.image_layout.toLowerCase()}`}
              >
                {content.primary.image_left.fluid !== null && (
                  <div className="image-left">
                    <ConditionalWrapper
                      condition={
                        content.primary.image_left_link !== null &&
                        content.primary.image_left_link.url !== ""
                      }
                      wrapper={(children) => (
                        <Link to={content.primary.image_left_link.url}>
                          {children}
                        </Link>
                      )}
                    >
                      <AspectRatioImageContainer
                        image={content.primary.image_left}
                      >
                        <img
                          srcSet={content.primary.image_left.fluid.srcSet}
                          src={content.primary.image_left.fluid.src}
                          alt={content.primary.image_left.alt}
                          loading="lazy"
                        />
                      </AspectRatioImageContainer>
                    </ConditionalWrapper>

                    <div
                      className="image-caption small-text"
                      dangerouslySetInnerHTML={{
                        __html: content.primary.image_left_caption.html,
                      }}
                    />
                  </div>
                )}

                {content.primary.image_right.fluid !== null && (
                  <div className="image-right">
                    <ConditionalWrapper
                      condition={
                        content.primary.image_right_link !== null &&
                        content.primary.image_right_link.url !== ""
                      }
                      wrapper={(children) => (
                        <Link to={content.primary.image_right_link.url}>
                          {children}
                        </Link>
                      )}
                    >
                      <AspectRatioImageContainer
                        image={content.primary.image_right}
                      >
                        <img
                          srcSet={content.primary.image_right.fluid.srcSet}
                          src={content.primary.image_right.fluid.src}
                          alt={content.primary.image_right.alt}
                          loading="lazy"
                        />
                      </AspectRatioImageContainer>
                    </ConditionalWrapper>

                    <div
                      className="image-caption small-text"
                      dangerouslySetInnerHTML={{
                        __html: content.primary.image_right_caption.html,
                      }}
                    />
                  </div>
                )}
              </div>
            </Grid>
          </Row>
        );
      }

      if (content.slice_type === "credits") {
        return (
          <Row key={content.id} className="text-row">
            <Grid>
              <div
                className="credits-text small-text"
                dangerouslySetInnerHTML={{
                  __html: content.primary.text.html,
                }}
              />
            </Grid>
          </Row>
        );
      }
    }
  );

  return (
    <Article
      key={`single_article_${conversation.conversation.document.id}`}
      ref={ref}
    >
      <TitleSpacer
        id={`${kebabCase(conversation.conversation.document.data.title.text)}`}
      />
      <TitleBar>
        <div className="details">
          <p className="date">
            {conversation.conversation.document.last_publication_date} —{" "}
            {conversation.conversation.document.data.category}
          </p>

          <div
            className="title bl-arctic-regular lowercase"
            dangerouslySetInnerHTML={{
              __html: conversation.conversation.document.data.title.html,
            }}
          />
        </div>
      </TitleBar>
      {conversationContent}
    </Article>
  );
};
