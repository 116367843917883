import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Utils
import kebabCase from "lodash.kebabcase";

// Components
import { SingleConversation } from "../components/conversations/single-conversation";

const Page = styled.div`
  & p {
    & a {
      &::before {
        content: "(";
      }
      &::after {
        content: ")";
      }
    }
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ContentContainer = styled.div`
  position: relative;
  margin: 0 -20px;
  width: calc(100% + 20px + 20px);

  @media (max-width: 767px) {
    margin: 0 -15px;
    width: calc(100% + 15px + 15px);
  }

  & .positioned-image {
    margin: 0 0 65px 0;

    &.center {
      &.small {
        grid-column: 5 / 9;
        // margin: 0 0 65px 0;
      }
      &.large {
        grid-column: 4 / 10;
        // margin: 0 0 65px 0;
      }
    }

    &.left.large {
      grid-column: 1 / 7;
      margin: 0 0 0 -20px;

      & .image-caption {
        padding: 0 0 0 20px;
      }

      @media (max-width: 767px) {
        grid-column: 1 / 6;
        margin: 0 0 65px -15px;
      }
    }

    &.left.small {
      grid-column: 1 / 4;
    }

    &.right.large {
      grid-column: 6 / 13;
      margin: 0 -20px 0 0;

      @media (max-width: 767px) {
        grid-column: 2 / 7;
        margin: 0 -15px 65px 0;
      }
    }

    &.right.small {
      grid-column: 9 / 13;

      @media (max-width: 767px) {
        grid-column: 4 / 7;
      }
    }
  }

  & .multi-positioned-images {
    &.layout-split {
      grid-column: 1 / 13;

      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 20px;

      @media (max-width: 767px) {
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 10px;
      }

      & .image-left {
        grid-column: span 6;

        width: calc(100% + 20px);
        margin-left: -20px;

        & .image-caption {
          padding-left: 20px;
        }

        @media (max-width: 767px) {
          grid-column: span 5;

          width: calc(100% + 15px);
          margin: 0 0 0 -15px;
        }
      }

      & .image-right {
        margin: 150% 0 0 0;
        grid-column: 9 / 13;

        @media (max-width: 767px) {
          margin: 65px 0;
          grid-column: span 3;
        }
      }

      @media (max-width: 767px) {
        grid-column: span 6;
      }
    }

    &.layout-center {
      grid-column: 4 / 12;

      @media (max-width: 767px) {
        grid-column: span 6;
        margin: 0 0 65px 0;
      }

      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;

      & .image-left {
        grid-column: span 1;
      }

      & .image-right {
        grid-column: span 1;
        margin: 32% 0 0 0;
      }
    }
  }

  & .positioned-text {
    max-width: 570px;

    &.left {
      grid-column: 1 / 5;
    }

    &.center {
      grid-column: 5 / 10;

      @media (max-width: 767px) {
        grid-column: 1/6;
      }
    }

    &.right {
      grid-column: 7 / 12;

      @media (max-width: 767px) {
        grid-column: 2/7;
      }
    }
  }

  & .credits-text {
    grid-column: 5 / 12;
    max-width: 570px;

    @media (max-width: 767px) {
      grid-column: 1 / 6;
    }
  }

  & .image-container {
    grid-column: 1/4;

    & .image-caption {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.03em;

      margin: 5px 0 0 0;

      @media (max-width: 600px) {
        font-size: 9px;
        line-height: 12px;
      }
    }

    @media (max-width: 767px) {
      order: 1;
    }
  }

  & .text-container {
    grid-column: 5 / 12;
    max-width: 810px;

    & strong {
      font-weight: normal;
      font-style: normal;
    }

    @media (max-width: 767px) {
      grid-column: span 6;
      order: 12;

      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 10px;
    }

    & .interview-container {
      max-width: 500px;
      margin: 0 0 70px 0;

      &:nth-of-type(odd) {
        @media (max-width: 767px) {
          margin: 0 0 25px 0;

          grid-column: 1/6;
        }
      }

      &:nth-of-type(even) {
        margin: 0 0 70px auto;

        @media (max-width: 767px) {
          margin: 0 0 25px auto;

          grid-column: 2/7;
        }
      }

      & .name {
        margin: 0;
      }

      & .text {
        font-size: 19px;
        line-height: 29px;
        letter-spacing: 0.02em;

        & p {
          margin: 0;
        }

        & a {
          &::before {
            content: "(";
          }
          &::after {
            content: ")";
          }
        }

        @media (max-width: 960px) {
          font-size: 14px;
          line-height: 19px;
        }

        @media (max-width: 600px) {
          font-size: 11px;
          line-height: 16px;
        }
      }
    }
  }
`;

const Conversations = ({ data }) => {
  const content = data.prismicConversations.data.conversations
    .filter((conversation) => conversation.conversation.document !== null)
    .map((conversation, index) => (
      <SingleConversation
        conversation={conversation}
        index={index}
        key={`single_conversation_${index}_${conversation.conversation.document.id}`}
      />
    ));

  return (
    <>
      <Helmet
        title={`Conversations | Metamorphoses`}
        meta={[
          {
            name: "title",
            content: `Conversations | Metamorphoses`,
          },
          {
            property: "og:title",
            content: `Conversations | Metamorphoses`,
          },
          {
            property: "twitter:title",
            content: `Conversations | Metamorphoses`,
          },
          {
            property: "og:url",
            content: `https://metamorphosesobjects.com${data.prismicConversations.url}/`,
          },
          {
            property: "twitter:url",
            content: `https://metamorphosesobjects.com${data.prismicConversations.url}/`,
          },
        ]}
      >
        {/* <link
          rel="canonical"
          href={`https://metamorphosesobjects.com${data.prismicConversations.url}/`}
        /> */}
      </Helmet>
      <Page>
        <Flex>
          <ContentContainer>{content}</ContentContainer>
        </Flex>
      </Page>
    </>
  );
};

export default withPreview(Conversations);

export const query = graphql`
  {
    prismicConversations {
      url
      data {
        conversations {
          conversation {
            document {
              ... on PrismicConversation {
                id
                last_publication_date(formatString: "MMMM, YYYY")
                data {
                  title {
                    html
                    text
                  }
                  category
                  body {
                    ... on PrismicConversationBodyCredits {
                      id
                      slice_type
                      primary {
                        text {
                          html
                        }
                      }
                    }
                    ... on PrismicConversationBodyText {
                      id
                      slice_type
                      primary {
                        text {
                          html
                        }
                        text_position
                      }
                    }
                    ... on PrismicConversationBodySingleImage {
                      id
                      slice_type
                      primary {
                        image {
                          alt
                          fluid {
                            src
                            srcSet
                            aspectRatio
                          }
                          dimensions {
                            width
                            height
                          }
                        }
                        image_caption {
                          html
                        }
                        image_position
                        image_size
                        link {
                          url
                        }
                      }
                    }
                    ... on PrismicConversationBodyTwoImages {
                      id
                      slice_type
                      primary {
                        image_left {
                          fluid {
                            srcSet
                            src
                            aspectRatio
                          }
                          dimensions {
                            width
                            height
                          }
                          alt
                        }
                        image_left_link {
                          url
                        }
                        image_left_caption {
                          html
                        }
                        image_right {
                          alt
                          fluid {
                            src
                            srcSet
                            aspectRatio
                          }
                          dimensions {
                            width
                            height
                          }
                        }
                        image_right_caption {
                          html
                        }
                        image_right_link {
                          url
                        }
                        image_layout
                      }
                    }
                    ... on PrismicConversationBodyInterviewText {
                      id
                      slice_type
                      items {
                        name
                        text {
                          html
                        }
                      }
                      primary {
                        image {
                          fluid {
                            src
                            srcSet
                            aspectRatio
                          }
                          dimensions {
                            width
                            height
                          }
                          alt
                        }
                        link {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
